import React from 'react'
import cx from 'clsx'

import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import Tooltip from '@src/components/Tooltip'

import { twMerge } from 'tailwind-merge'

type Option<TKey = string> = {
  key: TKey
  label: string
  text?: string
  Icon?: React.FC<{ className: string }>
}

export interface SwitchProps<T = string> {
  onSelect: (selected: T) => void
  className?: string
  options: Option<T>[]
  active: T
  label?: string
  tooltip?: string
  classes?: {
    active?: string
    item?: string
  }
  isOnboard?: boolean
  name?: string
  isReversible?: boolean
}

export function Switch<T = string>({
  onSelect,
  label,
  tooltip,
  options = [],
  active = options[0]?.key,
  className,
  classes,
  name,
  isOnboard = false,
  isReversible = false,
}: SwitchProps<T>) {
  const handleTypeClick = (type: T) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (options.length > 1 && isReversible) {
      onSelect?.(active === options[0].key ? options[1].key : options[0].key)
    } else {
      onSelect?.(type)
    }
  }

  if (!options.length) return null

  return (
    <>
      {label && (
        <div className={cx('flex items-center text-sm text-gray-700 mb-0.5')}>
          {tooltip ? (
            <>
              <span data-tooltip-id={`switch${label}`} className="flex items-center">
                {label}
                <QuestionMarkCircleIcon className="h-3 ml-1" />
              </span>
              <Tooltip id={`switch${label}`}>
                <span>{tooltip}</span>
              </Tooltip>
            </>
          ) : (
            label
          )}
        </div>
      )}
      <div
        className={twMerge(
          cx('bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-base flex', className),
        )}
        data-onboard={isOnboard ? `${name}-switch` : undefined}
      >
        {options.map((option, i) => (
          <button
            key={i}
            className={twMerge(
              cx(
                'flex flex-col items-center justify-center gap-1 flex-grow transition-colors duration-300 ease-in focus:outline-none rounded-base px-2 py-1.5 w-1/2 hover:text-brand_primary border border-transparent',
                active === option.key && ['bg-white', classes?.active],
                classes?.item,
              ),
            )}
            onClick={handleTypeClick(option.key)}
            type="button"
            data-qa={`${option.key}-switch`}
          >
            <div className="flex items-baseline justify-center">
              {option.Icon && <option.Icon className={cx('h-4 w-4', option.label && 'mr-1')} />}
              {option.label}
            </div>
            {option.text && <div className="text-gray-500 text-xs font-normal">{option.text}</div>}
          </button>
        ))}
      </div>
    </>
  )
}

export default Switch
